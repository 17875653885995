.FooterMenu {
    background: #05376f;
    padding: 25px 0px;
    text-align: center;
}

.FooterMenu ul li {
    display: inline-block;
}

.FooterMenu ul li a {
    display: inline-block;
    padding: 15px;
    color: #fff;
    text-transform: uppercase;
    font-weight: bold;
}

.footer .socialMenu ul {
    display: inline-block;
    line-height: 35px;
    margin-bottom: 15px;
}

.footer .socialMenu ul li {
    display: inline-block;
}

.socialMenu ul li a {
    display: inline-block;
    padding: 0px 15px;
    color: #164171;
    text-transform: uppercase;
    font-weight: bold;
}

.footer {
    background: #ffffff;
    color: #858585;
    padding: 50px 0px;
}

p.copyRight {
    padding: 10px 0px;
    margin-bottom: 0px;
}

.social_icons ul li {
    float: left;
}

.footer h4 {
    padding: 10px 0px;
}

.footer ul li {
    display: block;
}

.footer ul li a {
    color: #858585;
}

.footer .newsletter {
    background: #efc1c4;
    color: #333333;
    padding: 15px;
}

.footerStats {
    background: url(../../images/footerStats.png) center top no-repeat;
    min-height: 250px;
    background-size: cover !important;
    padding: 50px;
}

.backToTop {
    background: url(../../images/top.png) no-repeat center center;
    width: 40px;
    height: 40px;
    text-indent: -100000px;
    position: fixed;
    bottom: 30px;
    right: 30px;
    border-radius: 100%;
    background-size: 40px 40px;
    z-index: 1000;
}

.stats .inner {
    background: #00000090;
    text-align: center;
    padding: 25px;
    border-radius: 10px;
    color: #fff;
}

.stats .inner h5 {
    font-size: 30px;
    font-weight: bold;
}

.footer2 {
    background: #bddafa90;
    color: #858585;
    padding: 20px 0px;
}
.footer2 p strong {
    display: block;
}
.footer2 p:last-child {
    margin: 0px;
    padding: 0px;
}
.footer2 a {
    display: inline-block;
    color: #333;
    margin: 0px 5px;
}
